import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
 
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function hexToRgba(hex: string, alpha: number = 1): string {
  if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
      let cArray;
      cArray = hex.substring(1).split('');
      if(cArray.length === 3){
          cArray = [cArray[0], cArray[0], cArray[1], cArray[1], cArray[2], cArray[2]];
      }
      const c = '0x' + cArray.join('');
      const numericC = parseInt(c, 16); // Convert the hexadecimal string to a number
      return `rgba(${(numericC >> 16) & 255}, ${(numericC >> 8) & 255}, ${numericC & 255}, ${alpha})`;
  }
  throw new Error('Bad Hex');
}




export function bytesToSize(bytes: number): string {
  const sizes: string[] = ['B', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0B';
  const i: number = Math.floor(Math.log(bytes) / Math.log(1024));
  return Math.round(bytes / Math.pow(1024, i)).toFixed(1) + sizes[i];
}

export function timeDifference(current: number, previous: number): string {
  const msPerMinute: number = 60 * 1000;
  const msPerHour: number = msPerMinute * 60;
  const msPerDay: number = msPerHour * 24;
  const msPerMonth: number = msPerDay * 30;
  const msPerYear: number = msPerDay * 365;

  const elapsed: number = current - previous;

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + ' seconds ago';
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + ' minutes ago';
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + ' hours ago';
  } else if (elapsed < msPerMonth) {
    return Math.round(elapsed / msPerDay) + ' days ago';
  } else if (elapsed < msPerYear) {
    return Math.round(elapsed / msPerMonth) + ' months ago';
  } else {
    return Math.round(elapsed / msPerYear) + ' years ago';
  }
}


let lastId = 0;
export function newId(prefix='id') {
    lastId++;
    return `${prefix}${lastId}`;
}