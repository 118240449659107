import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["NextIntlProvider"] */ "/vercel/path0/src/components/providers/nextintl-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ParallaxProvider"] */ "/vercel/path0/src/components/providers/parallax-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/src/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/utility-patterns.css");
